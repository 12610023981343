:root {
  --white: #ffffff;
  --light: #eeeeee;
  --dark: #14161e;
  --primary: #0076ba;
  --secondary:  #1d96d1;
  --info: #53bfed;
  --accent1: #1db100;
  --accent2: #00cc39;
  --accent3: #7de47d;
  --success: #00c56d;
  --warning: #eed300;
  --danger:  #ff1e1c;
}
html, body {
  min-height: 100vh;
}
body {
  position: relative;
  padding: 70px 15px 15px;
}
body::after, body::before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 12px;
  content: "";
  background: linear-gradient(0deg, var(--accent1) 0%, var(--primary) 100%);
  background-size: 100vw 100vh;
  background-attachment: fixed;
}
body::after {
  right: 0;
}
body::before {
  left: 0;
}
.emptyDiagramm {
  display: flex;
  justify-content: center;
  height: 214px;
  align-items: center;
  border: var(--bs-border-width) solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius);
}
@media screen and (min-width: 768px) {
  body { padding: 70px 25px 25px; }
  body::after, body::before { width: 24px; }
}
a {
  text-decoration: none!important;
}
body, p, span {
  cursor: default;
}

/* Für Tabellenelemente */
table, th, td {
  cursor: default;
}
.errorAccordion {
  border-color: rgb(241, 174, 181)!important;
}
.errorAccordion > h2 > .accordion-button {
  background-color: rgb(248, 215, 218)!important;
}