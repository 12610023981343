.menu {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding:0 25px;
}
.menuLogo {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--primary);
}
.menuLogo > span {
  color: var(--accent1);
  cursor: pointer;
}
.menuLogoSubtitle {
  display: inline;
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: #404040;
  cursor: pointer;
}
.menuLinks {
  display: none;
}
@media screen and (min-width: 1024px) {
  .menuLinks {
    display: flex;
    gap: 25px;
    align-items: center;
  }
  .mobileMenuBtn {
    display: none!important;
  }
}
.menuLink {
  font-weight: 500;
}
.menuLink:hover {
  color: #14161e;
}
