.auth {
  display: flex;
  margin-top:-95px;
  height: calc(100vh);
  align-items: center;
  justify-content: center;
}
.auth > form {
  min-width: 300px;
  max-width: 300px;
}
.authFooter {
  font-size: 14px;
  margin: -20px 0 0;
  line-height: 1;
  text-align: center;
  font-weight: 400;
  color: rgba(0,0,0,0.5);
}
span.Werk {
  font-weight: 600;
  color: var(--primary);
}
span.APP {
  color: var(--accent1);
  font-weight: 600;
}